<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: layout.vue
Description:This file contains the layout  of the admin Screens.
-->
<template>
  <el-container
    direction="veritcal"
    class="layout"
    :class="!noAside ? 'layout--padding' : ''"
  >
    <el-aside v-if="!noAside" class="layout-aside-menu">
      <portal-target name="outsideAsideScroll"></portal-target>
      <slot name="deviceListMenu"></slot>
    </el-aside>
    <el-main
      :class="[noAside ? 'margin main-without-aside' : 'main-with-aside']"
    >
      <portal-target name="outsideMainScroll"></portal-target>
      <slot></slot>
    </el-main>
  </el-container>
</template>

<script>
export default {
  props: {
    noAside: {
      default: false
    }
  }
};
</script>

<style lang="scss">
.layout {
  position: fixed;
  top: 45px;
  bottom: 0px;
  width: 100vw;
  &.layout--padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .el-main {
    height: calc(100vh - 50px);
  }
  .el-aside {
    height: calc(100vh - 104px);
    position: sticky;
    top: 0px;
    width: 200px !important;
    overflow: hidden;
  }
  .main-without-aside {
    padding: 0px !important;
    bottom: 0px;
    overflow: hidden;
  }
  .margin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .main-with-aside {
    padding: 10px;
    overflow: hidden;
  }
}
</style>
