<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: defaultTable.vue
Description: This file comprises of common table component that is used in dealer site
-->
<template>
  <data-tables-server
    ref="dataTable"
    :data="tableData"
    :total="total"
    :size="size"
    :page-size="$constants.table['page-size']"
    :pagination-props="$constants.table['pagination-props']"
    :table-props="computedTableProps"
    @query-change="onQueryChange"
    @cell-click="handleCellClick"
    class="dealer-default-table er-data-tables"
  >
    <template v-for="(column, index) in columnsSelected">
      <el-table-column
        :key="index"
        v-show="column.visible"
        :label="column.label"
        :sortable="column.sortable"
        :prop="column.prop"
      >
        <template v-slot:header="scope">
          <slot
            :name="`${column.name.toLowerCase()}_header`"
            v-bind:header="{
              column: scope.column,
              $index: scope.$index,
              label: column.label,
              sub_label: column.sub_label || ''
            }"
          >
            {{ column.label }}
            {{ column.sub_label }}
          </slot>
        </template>

        <template slot-scope="scope">
          <slot
            :name="column.prop"
            v-bind:row="{ data: scope.row, path: column.prop }"
          >
            <template v-if="getRowValue(scope.row, column).type === 'array'">
              <el-tag
                size="mini"
                effect="dark"
                v-for="(val, index) in getRowValue(scope.row, column).value"
                :key="index"
                >{{ val }}</el-tag
              >
            </template>
            <template v-else>
              {{ getRowValue(scope.row, column).value }}
            </template>
          </slot>
        </template>
      </el-table-column>
    </template>
    <slot name="actionColumn">
      <el-table-column label="Actions" v-if="actionColumn">
        <er-button
          icon="el-icon-setting"
          size="small"
          :disabled="true"
        ></er-button>
      </el-table-column>
    </slot>
    <slot name="expand"></slot>
  </data-tables-server>
</template>

<script>
import dateUtils from "@/utils/dateUtils";
import filtersMixin from "@/mixins/filtersMixin";
export default {
  mixins: [filtersMixin],
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      default: () => [],
      type: Array
    },
    total: {
      default: 0,
      type: Number
    },
    size: {
      default: 0,
      type: Number
    },
    actionColumn: {
      default: false,
      type: Boolean
    },
    searchProperty: {
      default: "code",
      type: String
    },
    height: {
      default: "100%",
      type: String
    },
    placeHolderForSearch: {
      default: "device",
      type: String
    },
    tableProps: {
      default: () => ({}),
      type: Object
    }
  },
  data: function() {
    return {
      columnsSelected: this.columns.filter((column) => column.required),
      searchString: "",
      query: {},
      queryState: false,
      page: 1,
      limit: 30
    };
  },
  computed: {
    computedTableProps: function() {
      return {
        headerCellClassName: "settings-table-header-cell",
        height: this.height,
        ...this.tableProps
      };
    }
  },
  watch: {
    total: function(val) {
      this.handlePaginationHideAndUnhide();
    }
  },
  mounted() {
    this.handlePaginationHideAndUnhide();
  },
  methods: {
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName("pagination-bar");
      if (this.total === 0) {
        ele.item(0).style.display = "none";
      } else {
        ele.item(0).style.display = "block";
      }
    },
    getRowValue(object, columnObject) {
      const path = columnObject.prop;
      const type = columnObject.type;
      const value =
        typeof this.$lodash.result(object, path) === "undefined" ||
        this.$lodash.result(object, path) === "" ||
        this.$lodash.result(object, path) === null
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        return value && value !== "--"
          ? {
              type: "string",
              value: dateUtils.formatDate(
                new Date(value),
                this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
              )
            }
          : { type: "string", value: "--" };
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return { type: "string", value };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case "sort":
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case "size":
        case "page":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "init":
          this.query.page = 1;
          this.query.limit = this.$constants.table["page-size"];
          this.query.order_type = "asc";
          break;
        default:
          throw new Error(
            "Not implemented onquerychange for " + queryInfo.type
          );
      }
      this.$emit("loadData", this.query);
    },
    sort(property, orderType) {
      this.query.order_by = property;
      if (orderType === "descending") {
        this.query.order_type = "desc";
      } else if (orderType === "ascending") {
        this.query.order_type = "asc";
      } else if (orderType === null) {
        delete this.query.order_type;
        delete this.query.order_by;
      }
    },
    pagination(page, pageSize) {
      this.query.limit = pageSize;
      this.query.page = page;
      this.page = page;
      this.limit = pageSize;
    },
    async handleChange(string) {
      if (string === "") {
        this.query.page = this.page;
        this.query.limit = this.limit;
        delete this.query[this.searchProperty];
      } else {
        this.query[this.searchProperty] = string;
        await this.$emit("loadData", this.query);
        this.queryState = true;
      }
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("cell-click", row, column, cell, event);
    }
  }
};
</script>

<style lang="scss">
@mixin align-cell-content {
  text-align: left;
  justify-items: flex-start;
  align-items: center;
}
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.dealer-default-table {
  &.sc-table {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
    .el-table {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
     .el-table__body-wrapper {
          display: flex;
          flex-direction: column;
          min-height: 0;
          height: calc(100vh - 228px) !important;
          overflow-y: auto;
        }
    }
  }
  & > .pagination-bar {
    padding: 10px;
    .el-pagination {
      .el-pagination__total {
        margin-left: 24px;
        align-self: center;
        @include cell-font-size;
      }
      .el-pagination__sizes,
      .el-pagination__jump {
        @include cell-font-size;
        align-self: center;
        .el-input {
          .el-input__inner {
            height: 22px;
            line-height: 22px;
            @include cell-font-size;
          }
          .el-input__icon {
            line-height: 22px;
            @include cell-font-size;
          }
        }
      }
      &.el-pagination {
        .el-select .el-input {
          @include responsiveProperty(min-width, 90px, 110px, 120px);
          margin: unset;
        }
      }
      &.pagination-with-slot {
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-prev .el-icon,
        .btn-next .el-icon,
        .el-pager .number {
          @include cell-font-size;
        }
        .el-pagination__rightwrapper {
          display: flex;
          width: 60%;
          justify-content: flex-end;
        }
        .el-pagination__leftwrapper {
          width: 40%;
          justify-content: flex-end;
          display: flex;
        }
        &.el-pagination button {
          display: inline-block;
          @include cell-font-size;
          height: unset;
          line-height: unset;
          vertical-align: top;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          &.er-button {
            display: inline-flex;
            align-items: center;
            span {
              height: unset;
              line-height: unset;
              @include responsiveProperty(font-size, 11px, 13px, 15px);
            }
          }
          span.material-icons-round {
            min-width: 16px;
          }
        }
      }
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1966a1;
    color: #fff;
  }
  .el-table .descending .sort-caret.descending {
    border-top-color: #1966a1;
  }
  .el-table .ascending .sort-caret.ascending {
    border-bottom-color: #1966a1;
  }

  .el-table__row {
    td:first-child {
      cursor: pointer;
    }
  }
  div.cell .el-link.el-link--default {
    font-size: 12px;
    font-weight: 100;
  }
  .settings-table-header-cell {
    color: #333;
    font-weight: 500;
  }
  .el-input--mini .el-input__inner {
    height: 23px;
    line-height: 23px;
  }
  .el-table td,
  .el-table th {
    padding: 6px 0;
  }
  .el-table th > .cell {
    color: #233a73;
    font-weight: 600;
    line-height: 120%;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .el-table .sort-caret.descending {
    bottom: 0px;
  }
  .el-table .sort-caret.ascending {
    top: 0px;
  }

  .el-table .caret-wrapper {
    height: 22px;
    width: 10px;
  }

  .el-table td > .cell {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}
</style>
