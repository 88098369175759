var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dialog-form',{attrs:{"model":_vm.getNewPondGuard(),"schema":_vm.pondGuardFormFields,"showDialog":_vm.dialogVisible,"loading":_vm.dialogBoxLoading,"title":"Add PondGuard","backendValidations":_vm.createPGFormErrors,"deviceType":"POND_GUARD","shouldErrorHighlightShow":true},on:{"close-dialog":_vm.closeDialog,"save-details":_vm.handleSaveNewPondguard,"clear-validations":_vm.handleClearValidations}}),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"is-filters-set-absolute",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"tableProps":_vm.elTableProps,"addPadingToSearch":true,"button":true,"columnselector_require":true,"selectedColumnHeadersSortable":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code"},on:{"reload":_vm.handleLoadData,"loadData":_vm.handleLoadData,"cell-click":_vm.handleCellClick,"add-item-click":_vm.handleAddClick},scopedSlots:_vm._u([{key:"pondguard_code",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/admin/pondguards/" + (row.data._id))}},[_vm._v(_vm._s(row.data.code))])]}},{key:"v1_id",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.v1_id)+"\n      "+_vm._s(row.data.v1_code ? (" (" + (row.data.v1_code) + ")") : "")+"\n    ")]}},{key:"gateway_code",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.sensor_values ? row.data.sensor_values.gateway_code : "-")+"\n    ")]}},{key:"installed_date",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.on_boarded_at)))+"\n    ")]}},{key:"user_details",fn:function(ref){
var row = ref.row;
return [_vm._v("\n      "+_vm._s(_vm.ftm__objectValueByPath(
          row.data.user_details,
          "full_name",
          _vm.ftm__formatEmpty("--")
        ))+"\n    ")]}},{key:"last_communication",fn:function(ref){
        var row = ref.row;
return [(row.data.utc_last_communicated_at_status)?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(
            _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
          ))+"\n      ")]):[_vm._v(_vm._s(_vm.ftm__formatEmpty(
          _vm.adm__filterToFormatDate(row.data.utc_last_communicated_at)
        )))]]}},{key:"pond_status",fn:function(ref){
        var row = ref.row;
return [(row.data.pond_details && row.data.pond_details.status === 'ACTIVE')?_c('el-tag',{attrs:{"size":"mini","type":"success","effect":"dark"}},[_vm._v("\n        "+_vm._s(_vm.ftm__formatEmpty(
            'Active'
          ))+"\n      ")]):(row.data.pond_details && row.data.pond_details.status === 'INACTIVE')?[_vm._v(_vm._s(_vm.ftm__formatEmpty(
          'Inactive'
        )))]:[_vm._v(_vm._s(_vm.ftm__formatEmpty(
          '--'
        )))]]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add PondGuard")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }