var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"erDataTables",staticClass:"is-filters-set-absolute",attrs:{"tableProps":_vm.elTableProps,"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.CalibrationValues,"total":_vm.total,"addPadingToSearch":true,"button":false,"columnselector_require":true,"selectedColumnHeadersSortable":true,"search-property":"device_code","placeHolderForSearch":"Search_device_code"},on:{"reload":_vm.handleLoadData,"loadData":_vm.handleLoadData,"cell-click":_vm.handleCellClick,"cleared":_vm.handleLoadData},scopedSlots:_vm._u([{key:"date_and_time",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(_vm.ftm__formatEmpty(_vm.adm__filterToFormatDate(row.data.date)))+"\n  ")]}},{key:"temperature",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.data.sensor_values ? row.data.sensor_values.temperature : "--")+"\n  ")]}},{key:"dissolved_oxygen",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.data.sensor_values ? row.data.sensor_values.dissolved_oxygen : "--")+"\n  ")]}},{key:"solar_voltage",fn:function(ref){
var row = ref.row;
return [_vm._v("\n    "+_vm._s(row.data.sensor_values ? row.data.sensor_values.solar_voltage : "--")+"\n  ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }