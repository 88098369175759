<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pgFilters.vue
Description:This file contains the details the customer gateways with column wise filters
-->
<template>
  <div v-loading="loading">
    <div class="item-container is-filters-set-relative">
      <div class="table-filters-right">
        <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcel"
        ></er-download-drop-down>
        <er-column-selector
          :columns="computedColumns"
          keyToStoreDefaults="pond_guard_filters"
          keyToDecideDefaultCol="isVisible"
          :allow-check="true"
          :title="'Column Visibility'"
          @change-selection="handleChangeColumnSelection"
        ></er-column-selector>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridPGFilters"
      style="width: 100%; height: calc(100vh - 120px)"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :pagination="true"
      :frameworkComponents="frameworkComponents"
      :rowData="deviceData"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { mapActions } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import agGridStringCompareFilter from "@/components/superadmin/shared/agGridStringCompareFilter";
import XLSX from "xlsx";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import agGridLastCommCellRenderer from "@/components/superadmin/shared/agGridLastCommCellRenderer";
export default {
  mixins: [adminDatesHandlerMixin, errorHandlerMixin, filtersMixin],
  data() {
    return {
      columnDefs: null,
      rowData: null,
      frameworkComponents: null,
      loading: false,
      deviceDetails: [],
      gridOptions: {},
      gridApi: undefined,
      gridColumnApi: undefined
    };
  },
  components: {
    AgGridVue
  },
  computed: {
    computedColumns: function() {
      const columns = this.$lodash.cloneDeep(
        this.columnDefs.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    deviceData: function() {
      return this.$lodash.cloneDeep(this.deviceDetails).map((x) => {
        ["v1_parsing", "v2_parsing"].forEach((key) => {
          if (x.settings && x.settings[key]) {
            x.settings[key] = 1;
          } else {
            if (!x.settings) {
              x.settings = {};
            }
            x.settings[key] = 0;
          }
        });
        x.customer_names = x.user_details ? x.user_details.full_name : "--";
        return x;
      });
    }
  },
  async created() {
    await this.initComponent();
  },
  async beforeMount() {
    this.columnDefs = [
      {
        headerName: "Pondguard Code",
        isVisible: true,
        colId: "pondguard_code",
        field: "code",
        sortable: true,
        filter: true
      },
      {
        headerName: "Alias",
        isVisible: true,
        colId: "title",
        field: "title",
        sortable: true,
        filter: true
      },
      {
        headerName: "Customer Name",
        isVisible: true,
        colId: "customer_names",
        field: "customer_names",
        sortable: true,
        filter: true
      },
      {
        colId: "dealer_details.full_name",
        field: "dealer_details.full_name",
        headerName: "Dealer Name",
        isVisible: true,
        minWidth: 200
      },
      {
        headerName: "Customer Timezone",
        isVisible: true,
        colId: "user_details.timezone.name",
        field: "user_details.timezone.name",
        sortable: true,
        filter: true
      },
      {
        colId: "location_details.name",
        field: "location_details.name",
        headerName: "Location",
        isVisible: true,
        minWidth: 200,
        sortable: true,
        filter: true
      },
      {
        headerName: "Device Timezone",
        isVisible: true,
        colId: "device_timezone",
        field: "device_timezone",
        sortable: true,
        filter: true
      },
      {
        headerName: "Pond Name",
        isVisible: true,
        colId: "pond_details.title",
        field: "pond_details.title",
        sortable: true,
        filter: true
      },
      {
        headerName: "V1_id",
        isVisible: true,
        colId: "v1_code",
        field: "v1_code",
        sortable: true,
        filter: true
      },
      {
        headerName: "V2_id",
        isVisible: true,
        colId: "_id",
        field: "_id",
        sortable: true,
        filter: true
      },
      {
        headerName: "Gateway code",
        isVisible: true,
        colId: "gateway_code",
        field: "sensor_values.gateway_code",
        label: "Gateway code",
        sortable: true,
        required: true,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Data Interval",
        isVisible: true,
        colId: "data_interval",
        field: "settings.data_interval",
        label: "Data Interval",
        required: true,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Sampling Interval",
        isVisible: true,
        colId: "sampling_interval",
        field: "settings.sampling_interval",
        label: "Sampling Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Software Version",
        isVisible: true,
        colId: "software_version",
        field: "settings.software_version",
        sortable: true,
        filter: "agGridStringCompareFilter"
      },
      {
        headerName: "Hardware Version",
        isVisible: true,
        colId: "hardware_version",
        field: "settings.hardware_version",
        sortable: true,
        filter: true
      },
      {
        headerName: "DO Calibration Value",
        isVisible: true,
        colId: "do_calibration_value",
        field: "settings.do_calibration_value",
        label: "DO Calibration Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph4 Calibration Value",
        isVisible: true,
        colId: "ph4_calibration_value",
        field: "settings.ph4_calibration_value",
        label: "ph4 Calibration Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph7 Calibration Value",
        isVisible: true,
        colId: "ph7_calibration_value",
        field: "settings.ph7_calibration_value",
        label: "ph7 Calibration Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph9 Calibration Value",
        isVisible: true,
        colId: "ph9_calibration_value",
        field: "settings.ph9_calibration_value",
        label: "ph9 Calibration Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph4 Temperature Value",
        isVisible: true,
        colId: "ph4_temperature_value",
        field: "settings.ph4_temperature_value",
        label: "ph4 Temperature Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Diagnose Interval",
        isVisible: true,
        colId: "diagnose_interval",
        field: "settings.diagnose_interval",
        label: "Diagnose Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Data Reset Interval",
        isVisible: true,
        colId: "data_reset_interval",
        field: "settings.data_reset_interval",
        label: "Data Reset Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph Sensor Control",
        isVisible: true,
        colId: "ph_sensor_control",
        field: "settings.ph_sensor_control",
        label: "ph Sensor Control",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph Slope",
        isVisible: true,
        colId: "ph_slope",
        field: "settings.ph_slope",
        label: "ph Slope",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Temperature Sensor Control",
        isVisible: true,
        colId: "temperature_sensor_control",
        field: "settings.temperature_sensor_control",
        label: "Temperature Sensor Control",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph7 Temperature Value",
        isVisible: true,
        colId: "ph7_temperature_value",
        field: "settings.ph7_temperature_value",
        label: "ph7 Temperature Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph9 Temperature Value",
        isVisible: true,
        colId: "ph9_temperature_value",
        field: "settings.ph9_temperature_value",
        label: "ph9 Temperature Value",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Temperature Correction Factor",
        isVisible: true,
        colId: "temperature_correction_factor",
        field: "settings.temperature_correction_factor",
        type: "input_number",
        inputType: "number",
        label: "Temperature Correction Factor",
        model: "temperature_correction_factor",
        validationRules: "required",
        filter: true
      },
      {
        headerName: "DO Correction Factor",
        isVisible: true,
        colId: "do_correction_factor",
        field: "settings.do_correction_factor",
        label: "DO Correction Factor",
        required: true,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph Correction Factor",
        isVisible: true,
        colId: "ph_correction_factor",
        field: "settings.ph_correction_factor",
        label: "ph Correction Factor",
        required: true,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "lcd Sleep Mode",
        isVisible: true,
        colId: "lcd_sleep_mode",
        field: "settings.lcd_sleep_mode",
        label: "lcd Sleep Mode",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "LCD Sleep Interval",
        isVisible: true,
        colId: "lcd_sleep_interval",
        field: "settings.lcd_sleep_interval",
        label: "LCD Sleep Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "ph on Interval",
        isVisible: true,
        colId: "ph_on_interval",
        field: "settings.ph_on_interval",
        label: "ph on Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Self Cleaning Threshold Voltage",
        isVisible: true,
        colId: "self_cleaning_threshold_voltage",
        field: "settings.self_cleaning_threshold_voltage",
        label: "Self Cleaning Threshold Voltage",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Self Cleaning Interval",
        isVisible: true,
        colId: "self_cleaning_interval",
        field: "settings.self_cleaning_interval",
        label: "Self Cleaning Interval",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Self Clean on Time",
        isVisible: true,
        colId: "self_clean_on_time",
        field: "settings.self_clean_on_time",
        label: "Self Clean on Time",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Salinity",
        isVisible: true,
        colId: "salinity",
        field: "settings.salinity",
        label: "Salinity",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Pressure",
        isVisible: true,
        colId: "pressure",
        field: "settings.pressure",
        label: "Pressure",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Pond Depth",
        isVisible: true,
        colId: "pond_depth",
        field: "settings.pond_depth",
        label: "Pond Depth",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Distance From Water Surface",
        isVisible: true,
        colId: "distance_from_water_surface",
        field: "settings.distance_from_water_surface",
        label: "Distance From Water Surface",
        required: false,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Installed date",
        isVisible: true,
        colId: "on_boarded_at",
        field: "on_boarded_at",
        label: "Installed date",
        required: false,
        type: "date",
        sortable: true,
        minWidth: 100,
        filter: true
      },
      {
        headerName: "Last Communication",
        isVisible: true,
        colId: "utc_last_communicated_at",
        field: "utc_last_communicated_at",
        cellRenderer: "agGridLastCommCellRenderer",
        sortable: true,
        filter: "agDateColumnFilter",
        width: 230,
        filterParams: {
          comparator: (inputDate, cellValue) => {
            const cellDate = this.adm__dateUtilsLib.parse(
              cellValue,
              this.adm__dateUtilsLib.isoFormatString,
              new Date()
            );
            const formattedInputDate = inputDate;
            if (
              this.adm__dateUtilsLib.isBefore(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return -1;
            } else if (
              this.adm__dateUtilsLib.isAfter(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return 1;
            }
            return 0;
          },
          buttons: ["apply", "clear"]
        }
      }
      // {
      //   headerName: "Hardware Version",
      //   isVisible: true,
      //   colId: "hardware_version",
      //   field: "on_boarded_at",
      //   label: "Installed date",
      //   required: false,
      //   type: "date",
      //   sortable: true,
      //   minWidth: 100,
      //   filter: true
      // }
    ];
    this.frameworkComponents = {
      agGridStringCompareFilter: agGridStringCompareFilter,
      agGridLastCommCellRenderer: agGridLastCommCellRenderer
    };
    this.rowData = this.deviceDetails;
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi.columnModel
      ? this.gridOptions.columnApi.columnModel
      : this.gridOptions.columnApi.columnController;
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchAllSensorDeviceDetails: "fetchAllSensorDeviceDetails"
    }),
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnDefs.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridPGFilters &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    },
    handle_V2_Parsing(params) {
      return params.data.settings.v2_parsing;
    },
    handle_V1_Parsing(params) {
      return params.data.settings.v1_parsing;
    },
    getFormattedExcelDataVal(data, field) {
      let retVal = this.$lodash.get(data, field);
      if (field === "utc_last_communicated_at") {
        //  || field === 'on_boarded_at'
        retVal = this.ftm__formatEmpty(this.adm__filterToFormatDate(retVal));
      }
      return retVal;
    },
    handleDownloadExcel() {
      const columns = this.gridColumnApi.gridColumns.map((column) => [
        column.colDef.field,
        column.colDef.headerName
      ]);
      const xlData = [];
      this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        xlData.push(
          columns.reduce((acc, [field, headerName]) => {
            let cellDate = "";
            if (headerName === "Last Communication") {
              try {
                const data = this.$lodash.get(rowNode.data, field);
                const IstTimeZone = "Asia/Kolkata";
                if (data) {
                  cellDate = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      data,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );

                  console.log("data", cellDate);
                }
              } catch {
                // cellDate = "";
              }
              acc[headerName] = cellDate;
            } else {
              acc[headerName] = this.$lodash.get(rowNode.data, field);
            }
            return acc;
          }, {})
        );
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, "PGFilters.xlsx", {
        type: "binary",
        cellDates: true
      });
    },
    async initComponent() {
      try {
        this.loading = true;
        // const response = await this.fetchAllDeviceDetails({
        //   device_type: "pond_guard",
        //   params: {
        //     get_all: true
        //   }
        // });
        const response = await this.fetchAllSensorDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: false,
          params: {
            get_all: true
          }
        });
        this.deviceDetails = response.pond_guards;
        // this.deviceDetails = this.$store.getters["superadmin/getPondGuards"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.item-container {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}
</style>
