<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2022 -->
<!--
File Name: pondGuardLog.vue
Description:This file contains  ItemListSideBar(list of pondguards), Basic pondguard details
-->
<template>
  <el-container class="pond-guard-logs">
    <el-aside>
      <portal-target name="outsideAsideScroll"></portal-target>
      <item-list-side-bar
        :item-list="pgList"
        :init-item-id="pg_id"
        :showProperty="'code'"
        item-type="pondguards"
        :dataFetchMethod="loadPgData"
        :totalItemsCount="totalItemsCount"
        search-str-query-param="device_code"
        @cleared="loadPgData"
        @loadData="loadPgData"
        @itemClicked="handlePgSelected"
      ></item-list-side-bar>
    </el-aside>
    <el-main v-loading="loading" element-loading-background="white">
      <basic-customer-details
        :device-details="deviceDetails"
        :user-details="userDetails"
        :showCustomerDetails="true"
        :allowAddCustomer="false"
      ></basic-customer-details>
      <el-scrollbar
        class="pond-guard-device-networks-scroll"
        viewClass="pond-guard-device-networks-scroll-view"
        wrapClass="pond-guard-device-networks-scroll-wrap"
      >
        <el-tabs v-model="activeTab">
          <el-tab-pane label="Sensor Data" name="SensorLogs">
            <pg-super-admin-card-header
              @changedDateQuery="handleDateChangeQuery($event, 'sensor_logs')"
              :dateQuery="true"
              :timeQuery="false"
              :downloadOption="true"
              v-if="!refreshDates"
              :logsTab="false"
              @downloadZIPQuery="handleDownloadZIPQuery($event, 'sensor_logs')"
            ></pg-super-admin-card-header>
            <pg-graphs
              :key="pg_id"
              :device-details="deviceDetails"
              :loadingGraphName="loadingGraphName"
              :loadingGraphValue="loadingGraphValue"
              @changedDateQuery="handleChangedDateQuery"
            ></pg-graphs>
          </el-tab-pane>
          <el-tab-pane label="Logs" name="logs">
            <pg-super-admin-card-header
              @changedDateQuery="handleDateChangeQuery($event, 'logs')"
              :dateQuery="true"
              :timeQuery="false"
              :downloadOption="false"
              :logsTab="true"
              v-if="!refreshDates"
            ></pg-super-admin-card-header>
            <pg-logs-tab-graphs
              :key="pg_id"
              :device-details="deviceDetails"
              :loadingGraphName="loadingGraphName"
              :loadingGraphValue="loadingGraphValue"
              @changedDateQuery="handleChangedDateQuery"
            ></pg-logs-tab-graphs>
          </el-tab-pane>
        </el-tabs>
      </el-scrollbar>
      <!-- <shrimpTalkDeviceNetworks
        v-loading="loading"
        element-loading-background="white"
        class="shrimp-talk-device-networkd"
        :deviceDetails="deviceDetails"
        :customer-details="customerDetails"
      ></shrimpTalkDeviceNetworks> -->
    </el-main>
  </el-container>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import pgSuperAdminCardHeader from "@/components/superadmin/pond-guard/sensor-logs/pgSuperAdminCardHeader";
import itemListSideBar from "@/components/superadmin/shared/itemListSideBar";
// import shrimpTalkDeviceNetworks from "./shrimpTalkDeviceNetworks";
import basicCustomerDetails from "@/components/superadmin/shared/basicCustomerDetails";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import pgGraphs from "@/components/superadmin/pond-guard/pgGraphs";
import pgLogsTabGraphs from "@/components/superadmin/pond-guard/pgLogsTabGraphs";
import Vue from "vue";

import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin],
  emits: ["handleShrimpTalkSelected"],
  components: {
    itemListSideBar,
    basicCustomerDetails,
    pgGraphs,
    pgSuperAdminCardHeader,
    pgLogsTabGraphs
    // shrimpTalkDeviceNetworks
  },
  data: function () {
    return {
      data: { gateway_code: "" },
      loading: false,
      pond_guard_data: {},
      totalItemsCount: 100,
      emptyStr: "--",
      isPgIdAvailable: false,
      bus: new Vue(),
      loadingGraphName: "ph",
      loadingGraphValue: false,
      pg_id: "",
      activeTab: "SensorLogs",
      refreshDates: false
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondGuards: "getPondGuards",
    }),
    pgList() {
      return this.getPondGuards;
    },
    isShrimpTalkAvailable() {
      if (this.getPondGuards && this.getPondGuards.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    deviceDetails() {
      // return this.getGatewaysObj[this.gateway_id];
      return this.pond_guard_data;
    },
    deviceId() {
      return (this.deviceDetails || { _id: "No Data" })._id;
    },
    customerDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id];
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
        };
      }
    },
    pondGuardLIst() {
      return this.getPondGuards;
    },
    userDetails() {
      if (this.deviceDetails) {
        // return this.getUsersObj[this.deviceDetails.user_id]
        return this.deviceDetails.user_data;
      } else {
        return {
          first_name: "",
          last_name: "",
          phone: "",
        };
      }
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAPondGuardLatestSensorData: "fetchAPondGuardLatestSensorData",
      fetchAPondGuardLatestSalinityData: "fetchAPondGuardLatestSalinityData",
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      fetchADeviceData: "fetchADeviceData",
      fetchAllUsers: "fetchAllUsers",
      setPgId: "setPgId",
      fetchPGSensorDataDownloadCSV: "fetchPGSensorDataDownloadCSV"
    }),
    async refreshDatePicker() {
      this.refreshDates = true;
      setTimeout(() => {
        this.refreshDates = false;
      });
    },
    async handleDateChangeQuery(changedDate, parameter) {
      if (parameter === "sensor_logs") {
         await this.callStoreOnPgSensorData(changedDate, parameter);
      } else if (parameter === "logs") {
        await this.callStoreOnPgSensorData(changedDate, parameter);
      }
    },
    async handleDownloadZIPQuery(changedDate, parameter) {
      if (parameter === "sensor_logs") {
         await this.callStorePGSensorLogsDownloadZIP(changedDate, parameter);
      }
    },
    async initComponent() {
      try {
        this.loading = true;
        const pondGuardResponse = await this.fetchAllDeviceDetails({
          device_type: "pond_guard",
          infinite_scroll: false,
          params: { page: 1, limit: 19 },
        });
        this.totalItemsCount = pondGuardResponse.total;
        const pond_guard_id = (this.pondGuardLIst[0] || { _id: "No PG" })
          ._id;
        if (pond_guard_id) this.isPgIdAvailable = true;
        this.setPgId(pond_guard_id);
        await this.callStoreOnPondGuardData(pond_guard_id);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async callStorePGSensorLogsDownloadZIP(changedDateValue, parameter) {
      try {
        const params = {};
        params.from_date = changedDateValue.from_date;
        params.to_date = changedDateValue.to_date;
        if (this.deviceDetails._id) {
          this.loading = true;
          const response = await this.fetchPGSensorDataDownloadCSV({
            pg_code: this.pond_guard_data.code,
            params,
            responseType: "blob"
          });
          if (
            !response.data ||
            !response.data.type ||
            response.data.type !== "application/zip"
          ) {
            this.$message.warning("No Data Found in Selected Dates.");
          } else {
            let fileName = "";
            if (this.pond_guard_data.code && params.from_date && params.to_date) {
              fileName =
                "ST_" +
                this.pond_guard_data.code +
                "_" +
                params.from_date +
                "_" +
                params.to_date +
                ".zip";
            } else {
              fileName = "FeedLogs.zip";
            }
            var fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/zip" })
            );
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            this.nofm__notifySuccess({
              msgLangkey: {
                title: "Comn_download_success_msg",
                count: 0
              }
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async callStoreOnPgSensorData(changedDate, parameter) {
      const params = {};
      params.from_date = changedDate.from_date + "T00:00:00.000Z";
      params.to_date = changedDate.to_date + "T23:59:59.999Z";
      if (this.pond_guard_data._id && parameter === 'sensor_logs') {
        try {
          this.loading = true;
        await this.fetchAPondGuardLatestSensorData({
          pond_guard_id: this.pond_guard_data._id,
          params,
          graphParameter: "sensor-data",
        });
        // await this.fetchAPondGuardLatestSalinityData({
        //   pond_guard_id: this.pond_guard_data.code,
        //   params
        //   });
        } catch (err) {
          console.log('Error on fetching sensor data', err);
        } finally {
          this.loading = false;
        }
      } else if (this.pond_guard_data.code && parameter === 'logs') {
        try {
          this.loading = true;
        // await this.fetchAPondGuardLatestSensorData({
        //   pond_guard_id: this.pond_guard_data._id,
        //   params,
        //   graphParameter: "sensor-data",
        // });
        await this.fetchAPondGuardLatestSalinityData({
          pond_guard_id: this.pond_guard_data.code,
          params
          });
        } catch (err) {
          console.log('Error on fetching sensor data', err);
        } finally {
          this.loading = false;
        }
      }
    },
    async callStoreOnPondGuardData(pg_id) {
      try {
        this.loading = true;
        this.pond_guard_data = await this.fetchADeviceData({
          device_type: "pond_guard",
          device_id: pg_id,
        });

        const startOfToday = this.adm__dateUtilsLib.startOfDay(
          this.adm__getTodayInUserTZ(this.deviceTimeZoneStr)
        );
        const params = {
          from_date: this.adm__dateUtilsLib.formatDate(
            this.adm__dateUtilsLib.subtract(startOfToday, { days: 2 }),
            "yyyy-MM-dd"
          ) + "T00:00:00.000Z",
          to_date: this.adm__dateUtilsLib.formatDate(
            startOfToday,
            "yyyy-MM-dd"
          ) + "T23:59:59.999Z",
        };

        await this.fetchAPondGuardLatestSensorData({
          pond_guard_id: this.pond_guard_data._id,
          params,
          graphParameter: "sensor-data",
        });
        await this.fetchAPondGuardLatestSalinityData({
          pond_guard_id: this.pond_guard_data.code,
          params
          });
        this.data = this.$store.getters["superadmin/getSensorData"];
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handlePgSelected(pgId) {
      console.log("shrimp_talk_id", pgId);
      try {
        this.loading = true;
        this.$router.replace(
          `pondguards?tab=${this.$route.query.tab}&pg_id=${pgId}`
        );
        // const userTimeZone = this.pondGuardLIst.filter((item, i) => item)
        this.setPgId(pgId);
        await this.callStoreOnPondGuardData(pgId);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },

    async loadPgData({ query, infinite_scroll }) {
      if (
        query.device_code &&
        query.device_code.toUpperCase().startsWith("ERUVAKA")
      ) {
        query.device_code = query.device_code.substr(7).trim();
      }

      const response = await this.$store.dispatch(
        "superadmin/fetchAllDeviceDetails",
        {
          device_type: "pond_guard",
          infinite_scroll: infinite_scroll,
          params: query,
        }
      );
      this.totalItemsCount = response.total;
    },

    async handleChangedDateQuery(params, parameter = null) {
      try {
        this.loadingGraphName = parameter;
        this.loadingGraphValue = true;
        await this.callStoreOnPgLatestSensorData(this.pg_id, params, parameter);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loadingGraphValue = false;
      }
    }
  },
};
</script>

<style lang="scss">
.pond-guard-logs {
  .item-list-menu {
    height: 82vh;
  }
  .side-list-menu {
    width: 200px;
  }

  .pond-guard-device-networks-scroll {
    position: relative;
    height: calc(100vh - 150px);
    padding: 0 10px;
    margin-top: 10px;
    .el-divider--vertical {
      margin-left: 22px;
      height: 100%;
    }
  }
  .pond-guard-device-networks-scroll-view {
    padding-right: 10px;
    padding-bottom: 50px;
  }
  .pond-guard-device-networks-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .pond-guard-device-networks-scroll-wrap {
    height: calc(100vh - 170px);
    .el-tabs__header {
      margin-bottom: 5px;
    }
  }
}
</style>
