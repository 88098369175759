<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondGuardTableSA.vue
Description:This file contains the  combination of pondguardLatestValues,pondguardDeviceDetails,pondguardCalibrationValues
-->
<template>
  <layout :no-aside="true">
    <!-- <el-tabs
      v-model="activeName"
      @tab-click="handletabClick"
      class="pond-guard-table-sa"
    >
      <el-tab-pane label="Device Details" name="first"
        ><PondGuardDeviceDetails
      /></el-tab-pane>
      <el-tab-pane label="Latest Values of Devices" name="second"
        ><PondGuardLatestValues
      /></el-tab-pane>
      <el-tab-pane label="Calibration Values of Devices" name="third">
        <PondGuardCalibrationValues
      /></el-tab-pane>
      <el-tab-pane label="PondGuard Filters" name="fourth">
        <pg-filters
      /></el-tab-pane> -->
    <!-- <el-tab-pane label="Task" name="fourth"></el-tab-pane> -->
    <!-- </el-tabs> -->
    <er-top-route-nav type="tabs" :items="computedTabItems"></er-top-route-nav>
    <!-- </simplebar> -->
    <component
      :is="computedComponentName"
      @change-tab="handleChangeTabTo"
      :tabData="componentList[computedComponentName]"
    ></component>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import pondGuardDeviceDetails from "@/components/superadmin/pond-guard/PondGuardDeviceDetails";
import pondGuardLatestValues from "@/components/superadmin/pond-guard/PondGuardLatestValues";
import pondGuardData from "@/components/superadmin/pond-guard/pondGuardLog";
import pondGuardCalibrationValues from "@/components/superadmin/pond-guard/PondGuardCalibrationValues";
import pgFilters from "@/components/superadmin/pond-guard/pgFilters";
import layout from "@/components/superadmin/shared/layout";
const tabComponents = [
  { name: "pondGuardDeviceDetails", label: "PondGuard Details" },
  { name: "pondGuardData", label: "PondGuard Data" },
  { name: "pondGuardLatestValues", label: "PondGuard Latest Values" },
  { name: "pondGuardCalibrationValues", label: "pondGuard Calibration Values" },
  { name: "pgFilters", label: "PondGuard Filters" },
];
export default {
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      activeName: "first",
      componentList: {
        pondGuardDeviceDetails: {
          name: "details",
          label: "Device Details",
        },
        pondGuardData: {
          name: "data",
          label: "PondGuard Data",
        },
        pondGuardLatestValues: {
          name: "latest-values",
          label: "Latest Values of Devices",
        },
        pondGuardCalibrationValues: {
          name: "calibration-values",
          label: "Calibration Values of Devices",
        },
        pgFilters: {
          name: "filters",
          label: "PondGuard Filters",
        },
      },
    };
  },
  components: {
    layout,
    pondGuardDeviceDetails,
    pondGuardLatestValues,
    pondGuardCalibrationValues,
    pondGuardData,
    pgFilters,
  },
  computed: {
    computedTabItems: function () {
      return tabComponents;
    },
    computedComponentName: {
      get() {
        return this.$route.query.tab;
      },
      set(tabName) {
        this.componentName = tabName;
      },
    },
  },
  methods: {
    handletabClick(tab, event) {
      console.log(tab, event);
    },
    handleChangeTabTo: function (tabComponentName) {
      this.rdm__navigateToPathWithLocationId({
        query: { ...this.$route.query, tab: tabComponentName },
      });
    },
  },
};
</script>

<style lang="scss">
.el-menu {
  // margin-bottom: -41px !important;
  // position: absolute;
  z-index: 1;
}
.pond-guard-table-sa {
  .el-tabs__header {
    margin: 0px !important;
  }
}
</style>
